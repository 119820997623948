<template>
  <main id="content" role="main">
    <section class="report_group">
      <section class="report_box">
        <div class="letter-detail">
          <div class="sec-intro">
            <div class="title">
              <span>DREAMPLUS</span>
              <span>{{ letterDetail.title }}</span>
            </div>
            <router-link :to="{name:'LetterRead', params:{}}"  class="link-read">구독하기</router-link>
          </div>
          <!-- <div class="sec-content" v-html="letterDetail.content" style="word-wrap: break-word !important;"> -->
          <div class="sec-content">
            <TinyViewer :ref="tinyViewer" :description="letterDetail.content"></TinyViewer>
          </div>
          <div class="sec-speak">
            <router-link :to="{name:'LetterList', params:{}}"  class="link-old">지난호 보기</router-link>
            <!-- <div class="speak">
              드림플러스 뉴스레터에 하고싶은<br class="hidden" >
              말씀이 있으시다면,<br>
              아래 링크를 통해 이야기해 주세요!
            </div>
            <div class="link-to"><a href="https://forms.gle/t8Pa7BgPLoEJRTjY8">To.드림플러스 뉴스레터</a></div> -->
            <div class="sns">
              <ul>
                <li class="face"><a href="https://www.facebook.com/dreamplusasia" target='_blank'></a></li>
                <li class="insta"><a href="https://www.instagram.com/dreamplusasia/" target='_blank'></a></li>
                <li class="youtube"><a href="https://www.youtube.com/DREAMPLUS" target='_blank'></a></li>
                <li class="naver"><a href="https://blog.naver.com/hanwhadreamplus" target='_blank'></a></li>
              </ul>
            </div>
          </div>
          <div class="sec-bottom">
            <div class="logo"></div>
            <div class="info">
              <strong>드림플러스 강남</strong>
              <span>accelerating@dreamplus.asia</span>
              <span>02-520-1500</span>
              <span>서울 서초구 강남대로 311</span>
            </div>
            <router-link v-if="this.userInfo && isSubscript" :to="{name:'LetterMsg', params:{type:2, email: this.userInfo.email}}" class="link-stop" :aria-disabled="email">수신거부 <span>Unsubscribe</span>
            </router-link>
          </div>
        </div>
      </section>
    </section>
  </main>
</template>

<script>

import { mixin as mixinHelperUtils } from '@/common/helperUtils';
import { APIs } from '@/common/portalApi';
import TinyViewer from '@/components/base/TinyViewer.vue';

export default {
  name: 'LetterDetail',
  mixins: [
    mixinHelperUtils
  ],
  components: {
    TinyViewer
  },
  metaInfo() {
    return {
      meta: this.$route.meta.metaInfo
    };
  },
  data() {
    return {
      letterDetail: {
        id: null
      },
      email: null,
      isSubscript: false,
      userInfo: {}
    };
  },
  created() {
    this.letterDetail.id = this.$route.params.letterId;
    const userInfo = this.$store.getters.getUserInfo();
    if (userInfo) {
      this.userInfo = userInfo;
    }
    this.checkSubscription()
    this.getData();
  },
  mounted() {
    
  },
  methods: {
    checkSubscription() {
      if (this.userInfo && this.userInfo.email) {
        this.portalApiClient({
          url: APIs.CHECK_SUBSCRIPTION,
          method: 'post',
          data: {
            email: this.userInfo.email
          }
        }).then(({ data }) => {
          if (data.result) {
            if (data.data) {
              this.isSubscript = true
            }
          }
        });
      }
    },
    getData() {
      this.portalApiClient({
        url: `${APIs.NEWS_LETTER_DETAIL}/${this.$route.params.letterId}`,
        method: 'get',
      }).then(({ data }) => {
        if (data.result) {
          this.letterDetail = data.data;
        } else {
          this.showAlert(data.message);
        }
      });
    }
  },
};
</script>

<style>
</style>
